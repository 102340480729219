import React, { useState, useEffect } from "react";
import axios from "axios";
import ToolsContent from "../Components/ToolsContent";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import { Beforeunload } from "react-beforeunload";
import logo from "../Assets/bg.jpg";

import {
  AppLayout,
  ContentLayout,
  Header,
  SpaceBetween,
  Container,
  Grid,
  Form,
  FormField,
  Button,
  Input,
  Multiselect,
  RadioGroup,
  Flashbar,
  Icon,
  Box,
  Textarea,
  Modal,
  Table,
} from "@cloudscape-design/components";

function Apps() {
  const navigate = useNavigate();
  document.title = "Our Apps | Hansoftware";
  const [toolsOpen, setToolsOpen] = useState(true);
  const [responseSuccessful, setResponseSuccessful] = useState("");
  const [flashbarSuccess, setFlashbarSuccess] = useState([]);
  const [flashbarFail, setFlashbarFail] = useState([]);
  const [ticketLoading, setTicketLoading] = useState(false);
  const [numTicketsQueue, setNumTicketsQueue] = useState(0);
  const [currentQueueData, setCurrentQueueData] = useState([]);

  const [settingsVisible, setSettingsVisible] = useState(false);
  const [visible, setVisible] = useState(false);

  return (
    <div>
      <AppLayout
        //headerVariant="high-contrast"
        //headerSelector="#top-nav"
        maxContentWidth={10000}
        content={
          <div>
            <Modal
              onDismiss={() => {
                //setTicketSaveLoc(prevSaveLoc);
                setSettingsVisible(false);
              }}
              visible={settingsVisible}
              size="large"
              footer={
                <>
                  <Box float="left">
                    <Button
                      onClick={() => {
                        setSettingsVisible(false);
                      }}
                    >
                      Cancel
                    </Button>
                  </Box>

                  <Box float="right">
                    <Button
                      variant="primary"
                      onClick={() => {
                        setSettingsVisible(false);
                      }}
                    >
                      Save
                    </Button>
                  </Box>
                </>
              }
              header="Settings"
            >
              <FormField
                description="Where you want your tickets to save by default."
                label="Ticket Save Location"
              ></FormField>
            </Modal>
            <ContentLayout
              defaultPadding
              disableOverlap
              maxContentWidth={1200}
              header={
                <Box padding={{ vertical: "xxxl", horizontal: "xxxl" }}>
                  <Container>
                    <Box padding="s">
                      <Box
                        fontSize="display-l"
                        fontWeight="bold"
                        variant="h1"
                        padding="n"
                      >
                        Apps
                      </Box>
                      <Box fontSize="display-l" fontWeight="light">
                        All of the apps Hansoftware has developed
                      </Box>
                    </Box>
                  </Container>
                </Box>
              }
            >
              <SpaceBetween direction="vertical" size="s">
                {/* ----- Start Form Containers ----- */}
                <Grid
                  gridDefinition={[
                    { colspan: 6 },
                    { colspan: 6 },
                    { colspan: 6 },
                    { colspan: 6 },
                  ]}
                >
                  <Container
                    fitHeight
                    header={<Header variant="h3">Return Home</Header>}
                  >
                    <Button onClick={navigate("/")}>Home</Button>
                  </Container>
                  <Container
                    fitHeight
                    header={<Header variant="h3">Apps</Header>}
                  >
                    <SpaceBetween direction="vertical" size="m">
                      <Button
                        onClick={() => {
                          navigate("/apps/wikipuzzle");
                        }}
                      >
                        WikiPuzzle
                      </Button>
                    </SpaceBetween>
                  </Container>
                </Grid>
              </SpaceBetween>
            </ContentLayout>
          </div>
        }
        tools={ToolsContent.faq}
      />
    </div>
  );
}

export default Apps;
