import { useContext, useState, useEffect } from "react";
import { useNavigate, useLocation, Link } from "react-router-dom";
import { UserContext } from "./User";

import Icon, {
  DashboardOutlined,
  HomeOutlined,
  LoginOutlined,
  LogoutOutlined,
  BarChartOutlined,
  UsergroupAddOutlined,
  UnorderedListOutlined,
  MailOutlined,
  CaretRightOutlined,
  PicLeftOutlined,
  UserOutlined,
  GroupOutlined,
  ProductOutlined,
  BuildOutlined,
  FileTextOutlined,
  InfoCircleOutlined,
  DownOutlined,
} from "@ant-design/icons";
import { Menu, Image } from "antd";

import { ReactComponent as wikiPuzzleSVG } from "../Assets/wikipuzzle.svg";

import logo from "../Assets/hansoftwareWhite.png";
// at some point would like to have user's company logo and name in place of Hansoftware's
// only while user is logged in, and only on dashboard and program pages -- exclude landing and login
// I think it would add a nice touch to the program

export default function CustomTopNavigation() {
  const { isLoggedIn, logout } = useContext(UserContext);
  const [name, setName] = useState(localStorage.getItem("name"));
  const [email, setEmail] = useState(localStorage.getItem("email"));

  const [theme, setTheme] = useState("dark");
  const [current, setCurrent] = useState("1");
  const changeTheme = (value) => {
    setTheme(value ? "dark" : "light");
  };

  const handleNavigate = (e) => {
    if (e.key === "home" || e.key === "logo") {
      navigate("/");
    } else if (e.key === "logout") {
      logout();
      navigate("/");
    } else if (e.key === "user") {
      navigate(`/${e.key}`);
    } else if (e.key === "support") {
      // do nothing
    } else {
      navigate(`/${e.key}`);
    }
  };

  const navigate = useNavigate();

  useEffect(() => {
    if (isLoggedIn()) {
      setName(localStorage.getItem("name"));
    }
  }, [name]);

  const iconSize = 20;

  const [hovered, setHovered] = useState(false);
  const linkStyle = {
    color: hovered ? "#fff" : "#a6adb4",
    textDecoration: "none",
  };

  return (
    <Menu
      theme={theme}
      onClick={handleNavigate}
      style={{
        display: "flex",
        flex: 1,
        height: 55,
        paddingBottom: 5,
        paddingTop: 5,
        alignItems: "center",
        justifyContent: "center",
        fontWeight: "bold",
        top: 0,
        position: "sticky",
        zIndex: 10,
      }}
      defaultOpenKeys={[]}
      selectedKeys={[]}
      mode="horizontal"
    >
      <Menu.Item key="logo">
        <Image
          src={logo}
          height={50}
          preview={false}
          style={{ paddingTop: 5, paddingBottom: 5, paddingLeft: 10 }}
        />
      </Menu.Item>
      <Menu.Item
        key="home"
        icon={<HomeOutlined style={{ fontSize: iconSize }} />}
        style={{ paddingTop: 5, paddingBottom: 5, fontSize: 16 }}
      >
        Home
      </Menu.Item>
      <Menu.SubMenu
        key={"apps"}
        icon={<ProductOutlined style={{ fontSize: iconSize }} />}
        title={
          <Link
            to="/apps"
            onMouseEnter={() => setHovered(true)}
            onMouseLeave={() => setHovered(false)}
            style={linkStyle}
          >
            Apps{`\xa0\xa0`}
            <DownOutlined style={{ fontSize: 10 }} />
          </Link>
        }
        style={{ paddingTop: 5, paddingBottom: 5, fontSize: 16 }}
      >
        <Menu.Item
          key="apps/wikipuzzle"
          //icon={<BuildOutlined spin style={{ fontSize: iconSize }} />}
          icon={
            <Icon
              height={"50"}
              width={"50"}
              component={wikiPuzzleSVG}
              spin
              //style={{ borderRadius: 50 }}
            />
          }
          style={{ paddingTop: 5, paddingBottom: 5, fontSize: 16 }}
        >
          WikiPuzzle
        </Menu.Item>
      </Menu.SubMenu>
      <Menu.Item
        key="about"
        icon={<InfoCircleOutlined style={{ fontSize: iconSize }} />}
        style={{ paddingTop: 5, paddingBottom: 5, fontSize: 16 }}
      >
        About
      </Menu.Item>
      <Menu.Item
        key="privacy-policy"
        icon={<FileTextOutlined style={{ fontSize: iconSize }} />}
        style={{ paddingTop: 5, paddingBottom: 5, fontSize: 16 }}
      >
        Privacy Policy
      </Menu.Item>
      <Menu.Item
        key="support"
        icon={<MailOutlined style={{ fontSize: iconSize }} />}
        style={{ paddingTop: 5, paddingBottom: 5, fontSize: 16 }}
      >
        <a href="mailto:support@hansoftware.net" target="_blank">
          Support
        </a>
      </Menu.Item>

      {!isLoggedIn() ? (
        <Menu.Item
          key="login"
          icon={<LoginOutlined />}
          style={{
            paddingTop: 5,
            paddingBottom: 5,
            fontSize: 16,
            marginLeft: "auto",
          }} // pull element to right
        >
          Sign in
        </Menu.Item>
      ) : (
        <>
          <Menu.SubMenu
            key="dash"
            icon={<DashboardOutlined />}
            style={{
              paddingTop: 5,
              paddingBottom: 5,
              fontSize: 16,
            }}
            title={"Admin Dashboard"}
          >
            <Menu.Item key="dashboard" icon={<BarChartOutlined />}>
              Dashboard Overview
            </Menu.Item>
            <Menu.Item key="dashboard/job-tickets" icon={<PicLeftOutlined />}>
              Job Ticket Generator
            </Menu.Item>
            <Menu.Item
              key="dashboard/customer-list"
              icon={<UsergroupAddOutlined />}
            >
              Customer List
            </Menu.Item>
          </Menu.SubMenu>

          <Menu.SubMenu
            key="user"
            icon={<UserOutlined />}
            style={{
              paddingTop: 5,
              paddingBottom: 5,
              fontSize: 16,
              marginLeft: "auto",
            }}
            title={`${name}`}
          >
            <Menu.Item key="logout" icon={<LogoutOutlined />}>
              Sign out
            </Menu.Item>
          </Menu.SubMenu>
        </>
      )}
    </Menu>
  );
}
