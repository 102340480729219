import { useState } from "react";
import { Layout, Menu, Breadcrumb } from "antd";
import {
  HomeOutlined,
  AppstoreAddOutlined,
  SearchOutlined,
} from "@ant-design/icons";

const { Header, Content, Sider, Footer } = Layout;

export function WikiPuzzle() {
  const [navOpen, setNavOpen] = useState(true);

  const toggleNav = () => {
    setNavOpen(navOpen ^ 1);
  };

  document.title = "WikiPuzzle | Hansoftware";

  //<Link href="https://play.google.com/store/apps/details?id=com.hansoftware.wikipuzzle">
  //Click here to get WikiPuzzle!
  //</Link>

  return (
    <Layout style={{ minHeight: "100vh" }}>
      <Layout>
        {/* Main Content */}
        <Layout style={{ padding: "0 24px 24px" }}>
          {/* Breadcrumb */}
          <Breadcrumb style={{ margin: "16px 0" }}>
            <Breadcrumb.Item>Home</Breadcrumb.Item>
            <Breadcrumb.Item>Apps</Breadcrumb.Item>
            <Breadcrumb.Item>WikiPuzzle</Breadcrumb.Item>
          </Breadcrumb>

          <Content
            style={{
              padding: 24,
              margin: 0,
              minHeight: 280,
              background: "#fff",
            }}
          >
            {/* WikiPuzzle Play Store image/link */}
            <h1>WikiPuzzle</h1>
            <h4>The guessing game where you learn along the way!</h4>
            <h2>The Daily Puzzle</h2>
            <p>
              WikiPuzzle's main attraction, the Daily Puzzle, shows you 5 random
              events that have occurred in previous years. Your goal is to guess
              what year the event happened in the least amount of guesses as
              possible.
            </p>
          </Content>
        </Layout>
      </Layout>

      {/* Footer */}
      <Footer
        style={{
          textAlign: "center",
          backgroundColor: "#001529",
          color: "white",
        }}
      >
        Hansoftware © {Date.now()}
      </Footer>
    </Layout>
  );
}

export default WikiPuzzle;
