import { useState } from "react";
import {
  AppLayout,
  ContentLayout,
  SpaceBetween,
  Container,
  Header,
  Link,
} from "@cloudscape-design/components";

export function PrivacyPolicy() {
  const [navOpen, setNavOpen] = useState(true);

  const toggleNav = () => {
    setNavOpen(navOpen ^ 1);
  };

  document.title = "Privacy Policy | Hansoftware";

  return (
    <AppLayout
      headerSelector="#top-nav"
      navigationHide
      toolsHide
      content={
        <>
          <ContentLayout header={<Header variant="h1">Privacy Policy</Header>}>
            <SpaceBetween size="xxs" direction="vertical">
              <Container
                header={
                  <Header variant="h2">
                    {" "}
                    We do not collect any personal data.{" "}
                  </Header>
                }
              >
                <SpaceBetween size="xxxxs" direction="vertical">
                  Privacy of our users is important to us. We do not collect any
                  identifiable information about our users. Hansoftware uses
                  third party services (see below) to store anonymous data for
                  the purposes of giving our users a better experience.
                  Hansoftware has no access to your data within any app(s)
                  described below , inluding any anonymous data as well as any
                  app-specific feature.
                  <br />
                </SpaceBetween>
                <SpaceBetween size="xxxs" direction="vertical">
                  <Header variant="h2"> Scope </Header>
                  This Privacy Policy covers the use of your data when you
                  download and/or use the following application(s) (app(s)):
                  <ul>
                    <li>WikiPuzzle</li>
                    <li>Soundboard Studio</li>
                  </ul>
                </SpaceBetween>
                <SpaceBetween size="xxs" direction="vertical">
                  <Header variant="h2"> Third-party services </Header>
                  <ul>
                    <li>AdMob by Google</li>
                    <ul>
                      <li>
                        This service uses advertising ID to serve our users
                        personalized ads. Since these third-party services are
                        owned by Google, more information about how Google uses
                        your data, can be found on this link:{" "}
                        <Link
                          href="https://policies.google.com/technologies/partner-sites"
                          target="_blank"
                        >
                          How Google uses information from sites or apps that
                          use our services
                        </Link>
                        .
                      </li>
                    </ul>
                  </ul>
                </SpaceBetween>
                <SpaceBetween size="xxs" direction="vertical">
                  <Header variant="h2">Opt-in</Header>
                  By installing one of our applications, we have your consent to
                  use above-mentioned third-party services to collect data from
                  you.
                  <br />
                  <Header variant="h2">Opt-out</Header>
                  You have the right to opt-out of any data collection by
                  uninstalling our applications from your device.
                </SpaceBetween>
                <br />
                {/*
                <SpaceBetween size="xxs" direction="vertical">
                  <Header variant="h2">Special note for EU and UK users</Header>
                  <b></b>
                </SpaceBetween>
                On inital startup of the App(s) mentioned above, you may see a
                GDPR message, allowing you to consent to personalized
                advertisments. If you do not complete the form, you will not
                have access to the App. Your answers in the form will affect how
                you see advertisements, as well as how your data is or is not
                collected. See{" "}
                <Link
                  href="https://www.google.com/about/company/user-consent-policy/"
                  target="_blank"
                >
                  Google GDPR Policy
                </Link>{" "}
                for more information.
                <br />
                To revoke your consent if you have already given it, simply
                uninstall the App(s). You may reinstall the App(s) to change any
                answers you may have given in the GDPR form.
                <br />
                */}
                <br />
                If you have any questions, please direct them to{" "}
                <Link href="mailto:support@hansoftware.net" target="_blank">
                  <b>support@hansoftware.net</b>
                </Link>
                .
              </Container>
              <br />
              <br />
              <br />
            </SpaceBetween>
          </ContentLayout>
        </>
      }
    />
  );
}

export default PrivacyPolicy;
