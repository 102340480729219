import React from "react";
import { Outlet } from "react-router-dom";
import { useLocation } from "react-router-dom";
import Apps from "./apps";

export default function AppsRedirect() {
  const loc = useLocation();
  if (loc.pathname === "/apps" || loc.pathname === "/apps/") {
    return <Apps />;
  }
  return <Outlet />;
}
