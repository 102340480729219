import React, { useState, useEffect } from "react";
import axios from "axios";
import ToolsContent from "../Components/ToolsContent";
import { useLocation, useNavigate } from "react-router-dom";
import { Beforeunload } from "react-beforeunload";
import logo from "../Assets/bg.jpg";

import {
  AppLayout,
  ContentLayout,
  Header,
  SpaceBetween,
  Container,
  Grid,
  Form,
  FormField,
  Button,
  Input,
  Multiselect,
  RadioGroup,
  Flashbar,
  Icon,
  Box,
  Textarea,
  Modal,
  Table,
} from "@cloudscape-design/components";

function Landing() {
  const navigate = useNavigate();
  document.title = "Hansoftware | Home";
  const [toolsOpen, setToolsOpen] = useState(true);
  const [responseSuccessful, setResponseSuccessful] = useState("");
  const [flashbarSuccess, setFlashbarSuccess] = useState([]);
  const [flashbarFail, setFlashbarFail] = useState([]);
  const [ticketLoading, setTicketLoading] = useState(false);
  const [numTicketsQueue, setNumTicketsQueue] = useState(0);
  const [currentQueueData, setCurrentQueueData] = useState([]);

  const [settingsVisible, setSettingsVisible] = useState(false);
  const [visible, setVisible] = useState(false);

  return (
    <div style={{}}>
      <AppLayout
        headerVariant="high-contrast"
        headerSelector="#top-nav"
        toolsHide
        navigationHide
        //maxContentWidth={10000}
        content={
          <div>
            <Modal
              onDismiss={() => {
                //setTicketSaveLoc(prevSaveLoc);
                setSettingsVisible(false);
              }}
              visible={settingsVisible}
              size="large"
              footer={
                <>
                  <Box float="left">
                    <Button
                      onClick={() => {
                        setSettingsVisible(false);
                      }}
                    >
                      Cancel
                    </Button>
                  </Box>

                  <Box float="right">
                    <Button
                      variant="primary"
                      onClick={() => {
                        setSettingsVisible(false);
                      }}
                    >
                      Save
                    </Button>
                  </Box>
                </>
              }
              header="Settings"
            >
              <FormField
                description="Where you want your tickets to save by default."
                label="Ticket Save Location"
              ></FormField>
            </Modal>
            <ContentLayout
              defaultPadding
              //disableOverlap
              headerBackgroundStyle={(mode) =>
                `center center/cover url("${logo}")`
              }
              maxContentWidth={1200}
              header={
                <Header variant="h2">
                  <Container>
                    <Box class="box" padding="xl">
                      <Box fontSize="display-l" fontWeight="bold" variant="h1">
                        Hansoftware
                      </Box>
                      <Box fontSize="display-l" fontWeight="light">
                        Developing fun and entertaining mobile apps!
                      </Box>
                      <Box
                        variant="p"
                        color="text-body-secondary"
                        margin={{ top: "xs", bottom: "l" }}
                      >
                        Explore the rest of the site to learn more about us!
                        {"\n"}
                        This website is a work-in-progress. Please bear with us
                        while we work through the bugs.
                      </Box>
                      <Box float="left">
                        <Button variant="primary">Learn more</Button>
                      </Box>
                      <Box float="right">
                        <Button
                          onClick={() => {
                            navigate("/privacy-policy");
                          }}
                        >
                          Privacy Policy
                        </Button>
                      </Box>
                    </Box>
                  </Container>
                </Header>
              }
            >
              <SpaceBetween direction="vertical" size="s">
                {/* ----- Start Form Containers ----- */}
                <Grid
                  gridDefinition={[
                    { colspan: 6 },
                    { colspan: 6 },
                    { colspan: 6 },
                    { colspan: 6 },
                  ]}
                >
                  <Container
                    fitHeight
                    header={<Header variant="h3">WikiPuzzle</Header>}
                  >
                    <Box
                      variant="p"
                      color="text-body-primary"
                      margin={{ top: "xs", bottom: "l" }}
                    >
                      Our puzzle game created using content from Wikipedia.
                    </Box>
                    <Box float="left">
                      <Button
                        variant="primary"
                        onClick={() => navigate("/apps/wikipuzzle")}
                      >
                        Learn more
                      </Button>
                    </Box>
                  </Container>
                  <Container
                    fitHeight
                    header={<Header variant="h3">Extra 2</Header>}
                  ></Container>
                  <Container
                    fitHeight
                    header={<Header variant="h3">Extra 3</Header>}
                  ></Container>
                  <Container
                    fitHeight
                    header={<Header variant="h3">Extra 4</Header>}
                  >
                    <SpaceBetween direction="vertical" size="m"></SpaceBetween>
                  </Container>
                </Grid>
              </SpaceBetween>
            </ContentLayout>
          </div>
        }
        tools={ToolsContent.faq}
      />
    </div>
  );
}

export default Landing;
